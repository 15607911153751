<template>
  <div class="container" id="NumberConfirmation">
    <div class="confirmationContainer">
      <div class="verifyNumber">
        {{ $t("verify_phone.verify") }} {{ phoneNumberVerify }}
      </div>
      <div class="bottomContainer">
        <div class="noticeText font-size-md">
          {{ $t("verify_phone.waiting_to_detect") }}{{ phoneNumberVerify }}

          <router-link
            to="/profile"
            class="color-black hover-color-black text-decoration-underline"
          >
            {{ $t("verify_phone.wrong_number") }}
          </router-link>
        </div>
        <div class="digitCodeContainer">
          <div class="division">
            <input
              type="text"
              pattern="[0-9]*"
              inputmode="numeric"
              v-model="number1Input"
              ref="firstNumber"
              v-on:keydown="clearInput('firstNumber')"
              v-on:keyup="setFocus('secondNumber')"
              class="inputCodeStyle"
              placeholder="-"
            />
            <input
              type="text"
              pattern="[0-9]*"
              inputmode="numeric"
              v-model="number2Input"
              ref="secondNumber"
              v-on:keydown="clearInput('secondNumber')"
              v-on:keyup="setFocus('thirdNumber')"
              class="inputCodeStyle"
              placeholder="-"
            />
            <input
              type="text"
              pattern="[0-9]*"
              inputmode="numeric"
              v-model="number3Input"
              ref="thirdNumber"
              v-on:keydown="clearInput('thirdNumber')"
              v-on:keyup="setFocus('fourthNumber')"
              class="inputCodeStyle"
              placeholder="-"
            />
          </div>
          <div class="division">
            <input
              type="text"
              pattern="[0-9]*"
              inputmode="numeric"
              v-model="number4Input"
              ref="fourthNumber"
              v-on:keydown="clearInput('fourthNumber')"
              v-on:keyup="setFocus('fiveNumber')"
              class="inputCodeStyle"
              placeholder="-"
            />
            <input
              type="text"
              pattern="[0-9]*"
              inputmode="numeric"
              v-model="number5Input"
              ref="fiveNumber"
              v-on:keydown="clearInput('fiveNumber')"
              v-on:keyup="setFocus('sixNumber')"
              class="inputCodeStyle"
              placeholder="-"
            />
            <input
              type="text"
              pattern="[0-9]*"
              inputmode="numeric"
              v-model="number6Input"
              ref="sixNumber"
              v-on:keydown="clearInput('sixNumber')"
              v-on:keyup="verifyNumberCode"
              class="inputCodeStyle"
              placeholder="-"
            />
          </div>
        </div>
        <div class="enterDigit font-size-md">Enter 6-digit code</div>
        <div class="resend" @click="resendNumber">
          <div class="iconResend"></div>
          <div class="textResend font-size-md">RESEND SMS</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "VerifyPhone",

  data() {
    return {
      phoneNumberVerify: '',
      number1Input: "",
      number2Input: "",
      number3Input: "",
      number4Input: "",
      number5Input: "",
      number6Input: "",
      loading: false
    };
  },
  methods: {
    ...mapActions(['setErrors', "getProfile"]),

    clearInput: function (focusTarget) {
      switch (focusTarget) {
        case 'firstNumber':
          return this.number1Input = '';
        case 'secondNumber':
          return this.number2Input = '';
        case 'thirdNumber':
          return this.number3Input = '';
        case 'fourthNumber':
          return this.number4Input = '';
        case 'fiveNumber':
          return this.number5Input = '';
        case 'sixNumber':
          return this.number6Input = '';
        default:
          return focusTarget;
      }
    },
    setFocus: function (focusTarget) {
      this.$refs[focusTarget].focus();
    },

    async numberCode() {
      return new Promise((resolve, reject) => {

        const fullNumber = this.number1Input + this.number2Input + this.number3Input + this.number4Input + this.number5Input + this.number6Input
        let url = window.Routes.getRoute("verify_code_number");
        window.axios.post(url, {token: fullNumber}).then(
            (response) => {
              resolve(response.data);
            },
            (error) => {
              this.setErrors([error.response.data.errors])
              reject(error.response.data.errors);
            }
        );
      });
    },

    async verifyNumberCode() {
      this.loading = true
      try {
        await this.numberCode()
        await this.getProfile();
        if (this.$router.options.history.state.back === '/profile') {
          this.$router.back()
        } else {
          this.$router.push({
            path: `/`
          });
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }

    },

    async resendNumber() {
      return new Promise((resolve, reject) => {

        let url =
            window.Routes.getRoute("verify_number")
        window.axios.post(url, {phone: this.phoneNumberVerify}).then(
            (response) => {
              resolve(response.data);
            },
            (error) => {
              this.setErrors(error.response.data.errors)
              reject(error.response.data.errors);
            }
        );
      });
    },
  },
  computed: {
    ...mapGetters(["errors"]),
  },

  mounted() {
    this.$refs.firstNumber.focus();
    this.phoneNumberVerify = this.$route.params.number
  }
};
</script>

<style lang="scss" scoped>
.container {
  min-height: 100%;
  display: flex;
  justify-content: center;

  .confirmationContainer {
    padding-top: 90px;
    align-items: center;
    flex-direction: column;
    display: flex;

    .verifyNumber {
      line-height: 32px;
      font-size: 32px;
      font-weight: bold;
      color: map-get($colors, "black");
    }

    .bottomContainer {
      max-width: 310px;
      justify-content: center;
      align-items: center;
      display: flex;
      flex-direction: column;

      .noticeText {
        margin-top: 10px;
        font-weight: 400;
        color: #6f8c95;
        text-align: center;
      }

      .digitCodeContainer {
        margin-top: 40px;
        width: 100%;
        display: flex;
        flex-direction: row;
        padding: 10px;
        justify-content: space-between;
        max-width: 200px;
        border-bottom: 1px solid map-get($colors, "gray");

        .division {
          display: flex;
          flex-direction: row;

          .inputCodeStyle {
            width: 20px;
            border: none;
            text-align: center;
            font-size: 32px;
            color: map-get($colors, "black");

            &:focus {
              outline: none;
            }

            &::placeholder {
              color: #d6d6d6;
              font-weight: 100;
              font-stretch: extra-expanded;
            }
          }
        }
      }

      .enterDigit {
        font-weight: 400;
        margin-top: 15px;
        color: map-get($colors, "secondary");
      }

      .resend {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        margin-top: 70px;
        margin-bottom: 40px;
        border-bottom: 1px solid map-get($colors, "gray");
        padding: 15px;

        .iconResend {
          margin-right: 15px;
          width: 23px;
          height: 19px;
          background-repeat: no-repeat;
          background-position: center;
          background-image: url(~@/assets/sms.svg);
        }

        .textResend {
          color: map-get($colors, "black");
          font-weight: 500;
        }
      }
    }
  }
}
</style>
